<template>
  <div id="app">
    <!-- Title Header -->
    <header class="title">
      <h1> DISTINCT DICTIONARY</h1>
    </header>

    <main>
      <div class="search-box">
        <input 
          type="text"  
          class="search-bar"
          placeholder="Search for a word..."
          v-model="query"
          @keypress.enter="fetchDefinition"
        />
      </div>

      <div class="result" v-if="definition">
        <div class="word">{{ definition.word }}</div>
        <div class="phonetics" v-if="definition.phonetics.length > 0">
          <span v-for="(phonetic, index) in definition.phonetics" :key="index">
            {{ phonetic.text }}
          </span>
        </div>
        <div class="meanings">
          <div v-for="(meaning, index) in definition.meanings" :key="index">
            <h3>{{ meaning.partOfSpeech }}</h3>
            <ul>
              <li v-for="(def, i) in meaning.definitions" :key="i">
                {{ def.definition }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      query: '',
      definition: null,
    };
  },
  methods: {
    async fetchDefinition() {
      if (this.query.trim()) {
        try {
          const response = await axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${this.query}`);
          if (response.data && response.data.length > 0) {
            this.definition = response.data[0];
          } else {
            this.definition = null;
          }
        } catch (error) {
          this.definition = null;
        }
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Changed from center to flex-start */
  align-items: center;
  min-height: 100vh;
  padding-top: 50px; /* Added padding to move content up */
}

header.title {
  text-align: center;
  margin-bottom: 20px; /* Reduced margin to move title up */
}

header.title h1 {
  font-size: 36px;
  font-weight: bold;
  color: #0918f3;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

main {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px; /* Reduced margin to move search box up */
}

.search-box {
  width: 100%;
  margin-bottom: 20px;
}

.search-box .search-bar {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.result {
  margin-top: 20px;
}

.result .word {
  font-size: 24px;
  font-weight: 700;
}

.result .phonetics {
  font-size: 18px;
  color: #555;
}

.result .meanings {
  margin-top: 20px;
}

.result .meanings h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.result .meanings ul {
  list-style-type: disc;
  padding-left: 20px;
}

.result .meanings li {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
